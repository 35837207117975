button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 8px 0;
    width: 100%;
  }
  
  .prev-button {
    background-color: #f44336;
  }
  
  .questionnaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .option-container {
    margin: 8px 0;
  }

  .textbox {
    width: 300px;
    height: 40px;
    font-size: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
  }
  
  